import { Outlet, Navigate } from 'react-router-dom';

import { useGetCompany } from '@/features/company/api/getCompany';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';

type Props = {
  allowedFlag: 'can_trade_shares' | 'is_admin';
  element: JSX.Element;
  redirectPath?: string;
};

const CompanyFlagRoute = ({ allowedFlag, element, redirectPath = '/go-premium/no-access' }: Props) => {
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const { data: companyData } = useGetCompany({
    companyID: selectedCompany?.cid as number,
  });

  if (companyData && !companyData?.[allowedFlag]) {
    return <Navigate to={redirectPath} replace />;
  }

  return element ?? <Outlet />;
};

export default CompanyFlagRoute;
