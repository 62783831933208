import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, Flex, Grid, MenuProps, Space } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import { StyledHeader } from '@lib/theme/components/Layout';
import LogoImg from '@/assets/main-english-logo.svg';
import { StyledAvatar } from '@lib/theme/components/Avatar';
import { StarIcon } from '@/assets/Icon/star';
import { handleChangeLanguage } from '@/utils/handleChangeLanguage';
import SearchIcon from '@/components/Icons/SearchIcon';
import ArrowDownIcon from '@/components/Icons/ArrowDownIcon';
import { clearCookie } from '@/utils/storage';
import { useUserDataStore } from '@/stores/UserData';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { usePermissionsStore } from '@/stores/Permissions';
import { useUpdateProfileInfo } from '@/features/user/api/updateProfileInfo';
import { useMixpanel } from '@/hooks/useMixpanel';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import { useGetCountries } from '@/features/misc/getCountries';
import { useGetCompanyTypes } from '@/features/company/api/getCompanyTypes';
import { queryClient } from '@/lib/react-query';

import { HeaderGoPremiumButton } from './GoPreimum';
import { HeaderSearchInput } from './SearchInput';
import { HeaderLanguageButton } from './Language';
import NotificationDropdown from './NotificationDropdown';

const Header = () => {
  const { t, i18n } = useTranslation('layout', {
    keyPrefix: 'header',
  });
  const { userData, setUserData } = useUserDataStore((state) => state);
  const { setPermissions } = usePermissionsStore((state) => state);
  const { setCompaniesList, setSelectedCompany, isPremiumPlan, setExpiresAt, companiesList } = useSelectedCompanyStore(
    (state) => state,
  );
  const [firstName, setFirstName] = useState('');
  const [charactersUserAvatar, setCharactersUserAvatar] = useState('');
  const { sendMixpanelEvent } = useTrackEvent({ isGeneralEvent: true });

  const { mutate: updateLocal, isPending: isLoading } = useUpdateProfileInfo({
    postSuccess: (locale) => {
      if (userData) {
        setUserData({
          ...userData,
          userInfo: {
            ...userData.userInfo,
            locale,
          },
        });
      }

      handleChangeLanguage(locale);
    },
  });

  const { refetch: getCountries } = useGetCountries({});
  const { refetch: getCompanyTypes } = useGetCompanyTypes({});

  const onLocalChange = (locale: 'en' | 'ar') => {
    updateLocal({ payload: { locale } });
  };

  const navigate = useNavigate();
  const mixpanel = useMixpanel();

  const items: MenuProps['items'] = [
    {
      label: t('myProfile'),
      key: 'my-profile',
      onClick: () => {
        sendMixpanelEvent('header-my-profile-btn-clicked');
        navigate('/user/profile');
      },
    },
    {
      label: t('signOut'),
      key: 'sign-out',
      onClick: () => {
        queryClient.clear();
        clearCookie('access_token');
        clearCookie('refresh_token');

        setUserData(null);
        setPermissions([]);
        setCompaniesList([]);
        setExpiresAt(null);
        setSelectedCompany(null);
        sendMixpanelEvent('header-sign-out-btn-clicked');
        mixpanel?.reset();
        navigate('/');
      },
    },
  ];

  const menuProps = {
    items: [...items],
  };

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  useEffect(() => {
    if (userData) {
      const [name] = userData.userInfo.name.split(' ');
      setFirstName(name);
      const matches = userData?.userInfo.name.match(/\b(\w)/g);
      const acronym = matches?.join('');

      setCharactersUserAvatar(acronym as string);
      handleChangeLanguage(userData.userInfo.locale);
    }

    void getCountries();
    void getCompanyTypes();
  }, [userData, i18n.language, getCountries, getCompanyTypes]);

  return (
    <StyledHeader>
      <Space>
        <img src={LogoImg} alt="logo" />
      </Space>

      <Space style={{ width: '47%' }}>
        {false && <HeaderSearchInput placeholder={t('searchFor')} prefix={<SearchIcon />} />}
      </Space>
      <Flex gap={16} align="center">
        <HeaderLanguageButton
          loading={isLoading}
          size="small"
          icon={isLoading ? null : <>{i18n.language === 'en' ? 'ع' : 'En'}</>}
          onClick={() => {
            sendMixpanelEvent('header-lang-btn-clicked');
            onLocalChange(i18n.language === 'en' ? 'ar' : 'en');
          }}
          type="text"
        />
        {!companiesList.length
          ? null
          : !isPremiumPlan && (
              <HeaderGoPremiumButton
                size="small"
                type="primary"
                style={{
                  background: themeToken['branding-secondary-6'],
                  paddingInline: ` ${themeToken.paddingLG}px`,
                }}
                onClick={() => {
                  sendMixpanelEvent('header-go-premium-btn-clicked');
                  navigate('/go-premium/plans');
                }}
                icon={<StarIcon />}
              >
                {t('goPremium')}
              </HeaderGoPremiumButton>
            )}
        <NotificationDropdown />
        <Dropdown menu={menuProps}>
          <Space
            style={{
              cursor: 'pointer',
            }}
          >
            <Flex
              style={{
                boxShadow: `inset 0px 0px 0px 1px ${themeToken['branding-primary-6']}`,
                padding: '2px',
                borderRadius: '50%',
                height: '100%',
              }}
              align="center"
            >
              <StyledAvatar src={userData?.userInfo.profile_image}>{charactersUserAvatar}</StyledAvatar>
            </Flex>
            {screens.md && firstName}
            <ArrowDownIcon fill={screens.md ? themeToken['branding-primary-6'] : themeToken.white} />
          </Space>
        </Dropdown>
      </Flex>
    </StyledHeader>
  );
};

export default Header;
