import { useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Flex, Form, Grid, Input, Typography, notification } from 'antd';
import type { PaginationProps } from 'antd/es/pagination';
import type { SearchProps } from 'antd/es/input';

import { useFetchFolders } from '../api/getFolders';
import { useFetchFolderData } from '../api/getFolderById';

import FileUpload from '../components/FileUpload';

import themeToken from '@lib/theme/tokens/index';
import { StyledBreadcrumb } from '@/lib/theme/components/Breadcrumb';
import { StyledButton } from '@/lib/theme/components/Button';
import { PageWrapper } from '@/components/Shared/Wrapper';
import PlusIcon from '@/components/Icons/PlusIcon';
import { FolderCloudIcon } from '@/components/Icons/FolderCloudIcon';
import { usePermissionsStore } from '@/stores/Permissions';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { LimitsCheckerContext } from '@/components/LimitsChecker';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { StyledForm } from '@/components/Shared/Form';
import MainContainer from '@/components/Shared/MainContainer';

import { StyledPagination } from '../components/StyledPagination';
import CreateNewFolderForm from '../components/CreateNewFolderForm';
import DataRoomList from '../components/DataRoomList';
import { useSearchFolders } from '../api/searchFolders';
import DataroomHeaderButtons from '../components/DataroomHeaderButtons';
import { FolderData } from '../types';

const { Text } = Typography;
const { useBreakpoint } = Grid;
const { Search } = Input;

const DataRoomsMain = () => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'dataroom' });
  const { t } = useTranslation('dataroom');

  const { t: translate } = useTranslation('pageTitles');

  useDocumentTitle(translate('dataRoom'));

  const { limitations, disableRecordsAddition } = useOutletContext<LimitsCheckerContext>() || {};

  const { uuid } = useParams<{ uuid: string }>();
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const { permissions } = usePermissionsStore((state) => state);

  const screens = useBreakpoint();
  const [form] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const [isCreateNewFolderOpen, setIsCreateNewFolderOpen] = useState(false);

  const [parentFolderLevel, setParentFolderLevel] = useState(1);

  const [foldersBreadCrumbs, setFoldersBreadCrumbs] = useState<{ title: string; id: number }[]>([]);
  const [parentFolder, setParentFolder] = useState<FolderData | null>(null);

  const [paginationParams, setPaginationParams] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const [childPaginationParams, setChildPaginationParams] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const [searchToken, setSearchToken] = useState('');

  const {
    data: mainFoldersData,
    error,
    isLoading: isLoadingFolderData,
  } = useFetchFolders({ company_id: selectedCompany?.cid as number, page: paginationParams.current, uuid });

  const isFolderDownloadable = useMemo(() => {
    return mainFoldersData?.data?.find((folder) => folder.id === foldersBreadCrumbs[0]?.id)?.downloadable;
  }, [mainFoldersData, foldersBreadCrumbs]);

  const { data: folderData, isLoading: isFetchFolderDataLoading } = useFetchFolderData({
    company_id: selectedCompany?.cid as number,
    folder_id: foldersBreadCrumbs[foldersBreadCrumbs.length - 1]?.id,
    isFolderDownloadable,
    page: childPaginationParams.current,
    uuid,
  });

  const { data: searchFoldersData, isLoading: isSearchFoldersLoading } = useSearchFolders({
    companyID: selectedCompany?.cid as number,
    queryParams: {
      q: searchToken,
      page: paginationParams.current,
    },
  });

  const canAddNewFolder = useMemo(() => {
    if (uuid) return false;
    if (disableRecordsAddition) return false;
    const folderPlanlimit = limitations?.find(({ key }) => key === 'folder');
    if (folderPlanlimit && folderPlanlimit.limit === 0) return true;
    if (folderPlanlimit && folderPlanlimit.usage === folderPlanlimit.limit) return false;
    return true;
  }, [limitations]);

  const canAddNewFile = useMemo(() => {
    if (uuid) return false;
    if (disableRecordsAddition) return false;
    const filePlanlimit = limitations?.find(({ key }) => key === 'file');
    if (filePlanlimit && filePlanlimit.limit === 0) return true;
    if (filePlanlimit && filePlanlimit.usage === filePlanlimit.limit) return false;
    return true;
  }, [limitations]);

  useEffect(() => {
    if (searchFoldersData) {
      const { current, total } = searchFoldersData.metadata;
      setPaginationParams((prev) => ({ ...prev, total, current }));
    } else if (mainFoldersData) {
      const { current, total } = mainFoldersData.metadata;
      setPaginationParams((prev) => ({ ...prev, total, current }));
    }
  }, [mainFoldersData, searchFoldersData]);

  useEffect(() => {
    if (folderData) {
      const { current, total } = folderData.metadata;
      if (folderData.data.length === 0)
        setChildPaginationParams((prev) => ({ ...prev, total, current: prev.current - 1 || 1 }));
      else setChildPaginationParams((prev) => ({ ...prev, total, current }));
    }
  }, [folderData]);

  useEffect(() => {
    if (error) {
      api.error({
        message: 'Something Went Wrong!',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        description: error?.response?.data?.message,
      });
    }
  }, [error]);

  const onChange: PaginationProps['onChange'] = (page: number, pageSize: number) => {
    if (foldersBreadCrumbs.length > 0) {
      setChildPaginationParams((prev) => ({ ...prev, current: page, pageSize }));
    } else {
      setPaginationParams((prev) => ({ ...prev, current: page, pageSize }));
    }
  };

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearchToken(value);
    setPaginationParams((prev) => ({ ...prev, current: 1 }));
    setChildPaginationParams((prev) => ({ ...prev, current: 1 }));
    setFoldersBreadCrumbs([]);
  };

  return (
    <>
      {contextHolder}
      <MainContainer
        title={t('dataRoom')}
        breadcrumbItems={[
          {
            title: (
              <>
                <FolderCloudIcon width={12} height={20} />
                {t('dataRoom')}
              </>
            ),
          },
        ]}
        headerContent={<DataroomHeaderButtons />}
        hasHeader={!uuid}
      >
        <PageWrapper>
          <Flex
            vertical={screens.xs}
            gap={8}
            justify="space-between"
            align={screens.xs ? 'flex-start' : 'center'}
            style={{ marginBottom: `${themeToken.margin}px` }}
          >
            <Flex vertical>
              <Text
                style={{
                  color: themeToken['branding-primary-6'],
                  fontWeight: 600,
                  fontSize: themeToken.fontSizeLG,
                  marginBottom: themeToken.marginXXS,
                }}
              >
                {t('allCompanyFiles')}
              </Text>

              <StyledBreadcrumb
                style={{ marginBottom: 0 }}
                items={[
                  {
                    onClick: () => {
                      setPaginationParams((prev) => ({ ...prev, current: mainFoldersData?.metadata?.current ?? 1 }));
                      setChildPaginationParams((prev) => ({ ...prev, current: 1 }));
                      setFoldersBreadCrumbs([]);
                      setSearchToken('');
                      form.resetFields();
                    },
                    title: (
                      <Text
                        style={{
                          color: themeToken['branding-natural-6'],
                          fontSize: themeToken.fontSizeSM,
                          cursor: 'pointer',
                        }}
                      >
                        {t('yourFiles')}
                      </Text>
                    ),
                  },
                  ...foldersBreadCrumbs.map(({ title }, idx) => ({
                    onClick: () => {
                      if (
                        idx !== foldersBreadCrumbs.length - 1 &&
                        !isFetchFolderDataLoading &&
                        !isSearchFoldersLoading
                      ) {
                        setFoldersBreadCrumbs((prevState) => prevState.slice(0, idx + 1));
                      }
                    },
                    title: (
                      <Text
                        style={{
                          color: themeToken['branding-natural-6'],
                          fontSize: themeToken.fontSizeSM,
                          cursor:
                            idx !== foldersBreadCrumbs.length - 1 &&
                            !isFetchFolderDataLoading &&
                            !isSearchFoldersLoading
                              ? 'pointer'
                              : 'not-allowed',
                        }}
                      >
                        {title}
                      </Text>
                    ),
                  })),
                ]}
              />
            </Flex>
            {uuid ? null : (
              <Flex gap={8} vertical>
                <Flex gap={12}>
                  <StyledButton
                    style={{ padding: `${themeToken.paddingXXS}px ${themeToken.paddingLG}px` }}
                    size="small"
                    type="primary"
                    icon={<PlusIcon fill="#fff" />}
                    onClick={() => {
                      sendMixpanelEvent('new-folder-btn-clicked');
                      setParentFolderLevel(foldersBreadCrumbs.length + 1);
                      setIsCreateNewFolderOpen(true);
                    }}
                    disabled={!permissions.includes('company.dataroom.management') || !canAddNewFolder}
                  >
                    {t('newFolder')}
                  </StyledButton>

                  {foldersBreadCrumbs.length > 0 && (
                    <FileUpload
                      parentFolder={parentFolder}
                      folderId={foldersBreadCrumbs.at(-1)?.id ?? 0}
                      disabled={!permissions.includes('company.dataroom.management') || !canAddNewFile}
                    />
                  )}
                </Flex>
                <StyledForm
                  layout="vertical"
                  form={form}
                  disabled={isSearchFoldersLoading || isLoadingFolderData || isFetchFolderDataLoading}
                >
                  <Form.Item
                    style={{ margin: 0 }}
                    name="search"
                    rules={[{ type: 'string', min: 2, message: 'Enter at least 2 letters to search' }]}
                  >
                    <Search
                      placeholder="input search text"
                      allowClear
                      onSearch={onSearch}
                      loading={isSearchFoldersLoading}
                    />
                  </Form.Item>
                </StyledForm>
              </Flex>
            )}
          </Flex>
          <Divider
            style={{
              marginBlock: 0,
              borderColor: themeToken['branding-natural-3'],
            }}
          />

          <DataRoomList
            folderId={foldersBreadCrumbs.at(-1)?.id ?? 0}
            selectedFolder={
              foldersBreadCrumbs.length === 0 ? searchFoldersData?.data ?? mainFoldersData?.data : folderData?.data
            }
            isFetchFolderDataLoading={isFetchFolderDataLoading || isSearchFoldersLoading}
            setFoldersBreadCrumbs={setFoldersBreadCrumbs}
            uuid={uuid}
            setParentFolder={setParentFolder}
            isLoadingFolderData={isLoadingFolderData || isFetchFolderDataLoading || isSearchFoldersLoading}
          />

          <Flex justify="flex-end">
            <StyledPagination
              current={foldersBreadCrumbs.length === 0 ? paginationParams.current : childPaginationParams.current}
              total={foldersBreadCrumbs.length === 0 ? paginationParams.total : childPaginationParams.total}
              onChange={onChange}
            />
          </Flex>

          <CreateNewFolderForm
            isCreateNewFolderOpen={isCreateNewFolderOpen}
            handleCancel={() => setIsCreateNewFolderOpen(false)}
            parentFolderLevel={parentFolderLevel}
            folderId={foldersBreadCrumbs.at(-1)?.id ?? 0}
          />
        </PageWrapper>
      </MainContainer>
    </>
  );
};

export default DataRoomsMain;
