import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { axiosClient } from '@/lib/axios';

import type { ShareSaleDetails } from '../types';

type SharesParams = {
  companyID?: number;
  saleShareID: number;
};

type SharesResponse = {
  data: ShareSaleDetails;
};

export const getShareSale = async ({ companyID, saleShareID }: SharesParams): Promise<ShareSaleDetails> => {
  const { data } = await axiosClient.get<SharesResponse>(
    `companies/${companyID}/cap-table/equities/issued-shares/for-sale/${saleShareID}`,
  );

  return data.data;
};

type UseGetShareSale = {
  companyID?: number;
  saleShareID: number;
  config?: Partial<UseQueryOptions<ShareSaleDetails>>;
};

export const useGetShareSale = ({ companyID, saleShareID, config }: UseGetShareSale) => {
  return useQuery({
    queryKey: ['share-sale', companyID, saleShareID],
    queryFn: () => getShareSale({ companyID, saleShareID }),
    ...config,
    enabled: !!companyID && !!saleShareID,
  });
};
