import { useTranslation } from 'react-i18next';
import { Avatar, Flex, List, Typography } from 'antd';
import dayjs from 'dayjs';

import { formatNumber } from '@/utils/formatNumber';
import { StyledButton } from '@/lib/theme/components/Button';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { getNameInitials } from '@/utils/getNameInitials';
import { useGetCompany } from '@/features/company/api/getCompany';
import themeToken from '@lib/theme/tokens/index';

import ItemInfo from './ItemInfo';
import { useExpressInterest } from '../api/expressInterest';
import { StyledListItem } from './StyledListItem';
import type { MarketPlaceItem } from '../types';

const { Text } = Typography;

type Props = {
  item: MarketPlaceItem;
  handleSelectShare: (shareID: number) => void;
};

const ListItem = ({ item, handleSelectShare }: Props) => {
  const { t, i18n } = useTranslation('marketPlace');
  dayjs.locale(i18n.language);

  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const { data: companyData, isLoading: isCompanyDataLoading } = useGetCompany({
    companyID: selectedCompany?.cid as number,
  });
  const { mutate: expressInterest, isPending: isExpressInterestLoading } = useExpressInterest({
    companyID: selectedCompany?.cid as number,
    saleShareID: item.id,
  });

  const currencyCode = companyData?.company_data?.company_currency.code ?? '';

  const handleExpressInterest = () => {
    expressInterest({
      saleShareID: item.id,
      companyID: selectedCompany?.cid,
    });
  };

  return (
    <StyledListItem
      actions={[
        item.my_interest_status ? (
          <StyledButton
            key={'list-action'}
            onClick={() => handleSelectShare(item.id)}
            style={{
              fontWeight: themeToken.fontWeightXLG,
              color: themeToken['branding-natural-8'],
              width: i18n.language === 'en' ? '135px' : '175px',
            }}
          >
            {t('viewsellinfo')}
          </StyledButton>
        ) : (
          <StyledButton
            key={'list-action'}
            onClick={handleExpressInterest}
            type="primary"
            style={{
              fontWeight: themeToken.fontWeightXLG,
              color: themeToken.white,
              width: i18n.language === 'en' ? '135px' : '175px',
            }}
            disabled={isExpressInterestLoading}
          >
            {t('imInterested')}
          </StyledButton>
        ),
      ]}
    >
      <Flex justify="space-between" align="center">
        <List.Item.Meta
          avatar={
            <Avatar shape="square" size={32} style={{ backgroundColor: themeToken.colorPrimary, borderRadius: 1 }}>
              {getNameInitials(item.company_user.name)}
            </Avatar>
          }
          title={
            <Flex vertical>
              <Text
                style={{
                  color: themeToken['table-color-text'],
                  fontSize: themeToken.fontSizeLG,
                  fontWeight: 600,
                }}
              >
                {item?.company_user.name}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-6'],
                  fontSize: themeToken.fontSize,
                  fontWeight: 600,
                }}
              >
                {dayjs(item?.created_at).format('D MMM YYYY, HH:MM a')}
              </Text>
            </Flex>
          }
        />
        <Flex gap={16} flex={2} justify="space-between" align="center">
          <ItemInfo title={t('sharesNumber')} value={formatNumber(item.number_of_shares)} />
          <ItemInfo
            title={t('pricePerShare')}
            value={`${formatNumber(item.share_price)} ${currencyCode}`}
            isLoading={isCompanyDataLoading}
          />
          <ItemInfo
            title={t('totalCost')}
            value={`${formatNumber(item.total_price)} ${currencyCode}`}
            isLoading={isCompanyDataLoading}
          />
        </Flex>
      </Flex>
    </StyledListItem>
  );
};

export default ListItem;
