import styled from 'styled-components';
import dots from '../../../assets/auth/bg-dots.webp';
import leftFrame from '../../../assets/auth/left-bg-frame.webp';
import rightFrame from '../../../assets/auth/right-bg-frame.webp';

import themeToken from '@lib/theme/tokens/index';
import logo from '../../../assets/main-english-logo.svg';
import { Flex, Grid, Form } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  StyledContainer,
  StyledForm,
  StyledFormItem,
  StyledLeftDotsImage,
  StyledRightDotsImage,
  StyledSubmitButton,
  StyledText,
  StyledTitle,
} from '../components';
import { StyledInput } from '@lib/theme/components/Input';
import { StyledButton } from '@lib/theme/components/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { handleChangeLanguage } from '../../../utils/handleChangeLanguage';
import { useState, useEffect } from 'react';
import { useVerifyOTP } from '../api/verifyUserOTP';
import { clearCookie, setCookie } from '@/utils/storage';
import { useUserDataStore } from '@/stores/UserData';
import { useRequestOTP } from '../api/requestNewOTP';
import { useGetCompanies } from '@/features/company/api/getCompanies';
import { useGetCompany } from '@/features/company/api/getCompany';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { usePermissionsStore } from '@/stores/Permissions';
import { queryClient } from '@/lib/react-query';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import { Company } from '@/features/company/types';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

type FieldType = {
  otp: number;
};

const StyledWrapper = styled.div`
  height: auto;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledBackButton = styled(StyledButton)`
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: ${themeToken.borderRadiusLG}px;
`;

const StyledAccountVerificationForm = styled(StyledForm)`
  max-width: 470px;
  @media (min-width: ${themeToken.screenMD}px) {
    padding: ${themeToken.paddingXXL}px;
  }
`;

const ConfirmAccount = () => {
  const { sendMixpanelEvent, identifyMixpanelUser, mixpanel } = useTrackEvent({ page: 'confirm-account' });
  const { useBreakpoint } = Grid;
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { setUserData, userData } = useUserDataStore((state) => state);
  const { setPermissions } = usePermissionsStore((state) => state);
  const {
    setCompaniesList,
    setIsPremiumPlan,
    setExpiresAt,
    setSelectedCompany: setSelectedCompanyToStore,
  } = useSelectedCompanyStore((state) => state);

  const [searchParams] = useSearchParams();
  const [otpReceiver, setOtpReceiver] = useState<'email' | 'sms'>(searchParams.get('otp_receiver') as 'email' | 'sms');
  const phone = searchParams.get('phone') as string;
  const email = searchParams.get('email') as string;
  const method = searchParams.get('method') as string;

  const { t } = useTranslation(['confirmAccount', 'common']);
  const { t: translate } = useTranslation('pageTitles');

  useDocumentTitle(translate('confirmAccount'));

  const [isEmailRecieveOTP, setIsEmailRecieveOTP] = useState(otpReceiver === 'email');

  const [timeLeft, setTimeLeft] = useState<number | null>(60);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const {
    data: companiesList,
    refetch: getCompaniesList,
    status: companiesListFetchStatus,
  } = useGetCompanies({
    config: {
      enabled: false,
    },
  });

  const { data: companyData, refetch: getCompanyData } = useGetCompany({
    companyID: selectedCompany?.cid as number,
    config: {
      enabled: false,
    },
  });

  const { mutate: submitOtp, isPending: isConfirmLoading } = useVerifyOTP({
    handleAfterSuccess: (user) => {
      void sendMixpanelEvent('submit-otp', {}, { status: 'success', req_path: '/auth/verify-otp' });
      setCookie('access_token', user.accessToken);
      setCookie('refresh_token', user.refreshToken);
      setUserData(user);
      void identifyMixpanelUser(user.userInfo.mail);
      void mixpanel?.people.set({
        $name: user.userInfo.name,
        $email: user.userInfo.mail,
        $locale: user.userInfo.locale,
        $last_login: user.userInfo.lastLogin,
      });

      // Get companies and set them to store
      void getCompaniesList();
    },
    handleAfterFailure: (code) => {
      void sendMixpanelEvent('submit-otp', { statusCode: code }, { status: 'fail', req_path: '/auth/verify-otp' });
    },
  });

  const { mutate: requestNewOTP, isPending: isResendLoading } = useRequestOTP({
    handleAfterSuccess: () => {
      void sendMixpanelEvent('new-otp', {}, { status: 'success', req_path: '/auth/request-otp' });
      setTimeLeft(60);
      form.resetFields();
    },
    handleAfterFailure: (code) => {
      void sendMixpanelEvent('new-otp', { statusCode: code }, { status: 'fail', req_path: '/auth/request-otp' });
    },
  });

  const onOtpSubmit = ({ otp }: { otp: string }) => {
    void sendMixpanelEvent('submit-otp-btn-clicked');
    submitOtp({
      code: otp,
      to: otpReceiver === 'email' ? email : phone,
      type: otpReceiver,
    });
  };

  const resendOTP = (verifyMethod: 'sms' | 'email') => {
    void sendMixpanelEvent('resend-otp-btn-clicked');
    requestNewOTP({
      to: (verifyMethod || otpReceiver) === 'email' ? email : phone,
      type: verifyMethod || otpReceiver,
    });
  };

  const switchVerifyMethod = () => {
    if (otpReceiver === 'email') {
      resendOTP('sms');
      setOtpReceiver('sms');
      setIsEmailRecieveOTP((prev) => !prev);
    } else {
      resendOTP('email');
      setOtpReceiver('email');
      setIsEmailRecieveOTP(!isEmailRecieveOTP);
    }
  };

  useEffect(() => {
    queryClient.clear();
    clearCookie('access_token');
    clearCookie('refresh_token');
    setUserData(null);
    setPermissions([]);
    setCompaniesList([]);
    setSelectedCompany(null);
  }, []);

  useEffect(() => {
    if (companiesListFetchStatus === 'success' && !companiesList.length) {
      // Navigate to create company
      navigate('/company/new');
    }
  }, [companiesListFetchStatus, companiesList]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    // If there's a company selected before -> Set the selected company to store
    // If not -> Select the first company and set it to store
    if (companiesList && companiesList.length) {
      setCompaniesList(companiesList);
      if (userData?.userInfo.current_company) {
        const [fallbackCompany] = companiesList;
        const currentCompany = companiesList.find((company) => company.cid === userData?.userInfo.current_company);

        setSelectedCompany(currentCompany ?? fallbackCompany);
      }
    }
  }, [companiesList]);

  useEffect(() => {
    // Fetch the company details and permissions
    if (selectedCompany) {
      void getCompanyData();
    }
  }, [selectedCompany]);

  useEffect(() => {
    // Set Permissions
    // Navigate to company (is_owner -> dashboard) | (!is_owner -> my-investment)
    if (companyData) {
      setExpiresAt(companyData.expired_at);
      setIsPremiumPlan(companyData?.current_plan?.price > 0);
      setPermissions(companyData.user_permissions);
      setSelectedCompanyToStore(selectedCompany);

      navigate(
        selectedCompany?.is_owner
          ? companyData.user_permissions.includes('company.forsale') && !companyData.company_data.is_listed
            ? '/company/dashboard?forsale_modal=1'
            : '/company/dashboard'
          : '/company/investment-overview/my-investments',
      );
    }
  }, [companyData]);

  return (
    <StyledContainer>
      <StyledLeftDotsImage
        src={screens.md ? leftFrame : dots}
        width={screens.md ? 192 : 55}
        height={screens.md ? 80 : 180}
      />
      <StyledWrapper
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '36px',
          zIndex: 1,
        }}
      >
        <StyledWrapper>
          <StyledAccountVerificationForm form={form} onFinish={onOtpSubmit}>
            <StyledBackButton
              size="middle"
              type="primary"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                navigate(-1);
              }}
            />
            <img
              width={86}
              height={30}
              src={logo}
              alt="logo"
              style={{
                marginBottom: 24,
              }}
            />

            <StyledText color={themeToken['branding-natural-6']}>{t('sendOTP')}</StyledText>
            <StyledTitle
              level={screens.md ? 3 : 4}
              color={themeToken['branding-natural-9']}
              rootClassName="title"
              style={{
                marginBottom: '4px',
              }}
            >
              {t('accountVerification')}
            </StyledTitle>

            <StyledText
              color={themeToken['branding-natural-7']}
              style={{
                textAlign: 'center',
                marginBottom: '24px',
              }}
            >
              {t('weHaveSentOTP', {
                method: isEmailRecieveOTP ? t('common:emailAddress') : t('common:phoneNumber'),
                contact: isEmailRecieveOTP ? email : `+${phone}`,
              })}
            </StyledText>

            <StyledFormItem<FieldType>
              name="otp"
              rules={[{ required: true, message: 'Please input your OTP' }]}
              rootClassName="form-item"
            >
              <StyledInput size="large" placeholder={t('enterCode')} />
            </StyledFormItem>

            <StyledSubmitButton
              id="confirm_otp"
              loading={isConfirmLoading}
              type="primary"
              size="large"
              htmlType="submit"
              style={{
                marginTop: 0,
                marginBottom: 24,
              }}
            >
              {t('common:confirm')}
            </StyledSubmitButton>
            {timeLeft ? (
              <Flex wrap="wrap">
                <StyledText color={themeToken['neutral-color-white-black-200']}>
                  {t('resendOTPAfter')}{' '}
                  <StyledText
                    style={{
                      fontWeight: 'bold',
                    }}
                    color={themeToken['secondary-chrysler-blue-600']}
                  >
                    00:{!timeLeft ? '00' : timeLeft < 10 ? `0${timeLeft}` : timeLeft}
                  </StyledText>
                </StyledText>
              </Flex>
            ) : (
              <>
                <StyledSubmitButton
                  loading={isResendLoading}
                  type="link"
                  size="small"
                  style={{
                    color: '#6430DA',
                    fontWeight: 400,
                  }}
                  onClick={() => resendOTP(otpReceiver)}
                >
                  Send Code again
                </StyledSubmitButton>
                {method === 'signup' && (
                  <StyledButton
                    shape="round"
                    style={{
                      marginTop: 20,
                    }}
                    onClick={switchVerifyMethod}
                  >
                    {isEmailRecieveOTP ? t('usePhoneToReceiveOTP') : t('useEmailToReceiveOTP')}
                  </StyledButton>
                )}
              </>
            )}
          </StyledAccountVerificationForm>
        </StyledWrapper>
        <Flex>
          <StyledButton
            size="small"
            type="link"
            style={{
              color: themeToken.colorPrimary,
            }}
            onClick={() => {
              handleChangeLanguage('ar');
            }}
          >
            العربية
          </StyledButton>
          <StyledButton
            size="small"
            type="link"
            style={{
              color: themeToken.colorPrimary,
            }}
            onClick={() => {
              handleChangeLanguage('en');
            }}
          >
            English(US)
          </StyledButton>
        </Flex>
      </StyledWrapper>
      <StyledRightDotsImage
        src={screens.md ? rightFrame : dots}
        width={screens.md ? 'auto' : 55}
        height={screens.md ? 'auto' : 180}
      />
    </StyledContainer>
  );
};

export default ConfirmAccount;
