import { useTranslation } from 'react-i18next';
import { Divider, Flex, Form } from 'antd';

import CommonModal from '@/features/captable/components/Shared/CommonModal';
import { StyledButton } from '@/lib/theme/components/Button';
import { StyledInput } from '@/lib/theme/components/Input';
import ShareTransferIcon from '@/components/Icons/ShareTransferIcon';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { StyledInputNumber } from '@/features/auth/components';
import { formatNumber } from '@/utils/formatNumber';
import themeToken from '@lib/theme/tokens/index';

import { useAddSellShare, AddSellSharePayload } from '../../api/addSellShare';
import type { ShareDetail } from '../../types';

type SellSharesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  issueShare: ShareDetail;
};

const SellSharesModal = ({ isOpen, onClose, issueShare }: SellSharesModalProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });

  const selectedCompany = useSelectedCompanyStore((state) => state.selectedCompany);

  const { mutate: addSellShare, isPending: isAddSellSharePending } = useAddSellShare({
    postSuccess: onClose,
  });

  const onAddSellShare = (values: AddSellSharePayload['payload']) => {
    const payload = {
      number_of_shares: Number(values.number_of_shares),
      share_price: Number(values.share_price),
      issue_share_id: issueShare.id,
    };
    addSellShare({
      companyID: selectedCompany?.cid as number,
      payload: payload,
    });
  };

  return (
    <CommonModal isModalOpen={isOpen} handleCancel={onClose}>
      <Flex align="start" gap={8}>
        <div style={{ marginTop: '1%' }}>
          <ShareTransferIcon />
        </div>
        <h2 style={{ color: themeToken.primary_900, fontWeight: 500 }}>{t('sellShares')}</h2>
      </Flex>
      <Divider style={{ margin: '0', marginBottom: '1%' }} />
      <Form form={form} onFinish={onAddSellShare} layout="vertical">
        <Form.Item
          name="number_of_shares"
          label={t('numberOfSharesToSellLabel')}
          rules={[
            {
              required: true,
              message: 'Please enter number of shares to sell',
            },
            {
              type: 'number',
              max: issueShare?.shares_authorized,
              message: `Please set a number less the the available shares`,
            },
          ]}
        >
          <StyledInputNumber
            formatter={(value) => formatNumber(value as number, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            placeholder={t('numberOfSharesToSellPlacholder')}
          />
        </Form.Item>
        <Form.Item
          name="share_price"
          label={t('pricePerShare')}
          rules={[
            {
              required: true,
              message: 'Please enter price per share',
            },
          ]}
        >
          <StyledInput type="number" placeholder={t('pricePerShare')} />
        </Form.Item>

        <Flex justify="space-between">
          <StyledButton ghost type="primary" onClick={onClose}>
            {t('back')}
          </StyledButton>
          <StyledButton disabled={isAddSellSharePending} type="primary" htmlType="submit">
            {t('submit')}
          </StyledButton>
        </Flex>
      </Form>
    </CommonModal>
  );
};

export default SellSharesModal;
