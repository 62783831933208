import { StyledSider } from '@lib/theme/components/Layout';
import { StyledMenu } from '@lib/theme/components/Menu';
import CompaniesSidebar from './CompaniesSidebar';
import DashboardIcon from '../Icons/DashboardIcon';
import EmptyWalletIcon from '../Icons/EmptyWalletIcon';
import GraphIcon from '../Icons/GraphIcon';
import PresentationChartIcon from '../Icons/PresentationChartIcon';
import ChartSquareIcon from '../Icons/ChartSquareIcon';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useGetCompany } from '@/features/company/api/getCompany';
import { StyledButton } from '@/lib/theme/components/Button';
import { FolderCloudIcon } from '../Icons/FolderCloudIcon';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import AddOnsIcon from '../Icons/AddOnsIcon';
import { useTranslation } from 'react-i18next';
import PlusIcon from '../Icons/PlusIcon';
import MinusIcon from '../Icons/MinusIcon';
import { Flex, Grid, Tooltip, Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import ResponsiveSidebar from './components/Sidebar/ResponsiveSidebar';
import { Key, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CellSkeleton } from '../Shared/CellSkeleton';
import { UserIcon } from '../Icons/UserIcon';
import { usePermissionsStore } from '@/stores/Permissions';
import { EditCompanyIcon } from '../Icons/EditCompanyIcon';
import PremiumBadge from '../Shared/PremiumBadge';
import MiskIcon from '../Icons/MiskIcon';
import ActiveMiskIcon from '../Icons/ActiveMiskIcon';
import ActiveEmptyWalletIcon from '../Icons/ActiveEmptyWalletIcon';
import ActiveFolderCloudIcon from '../Icons/ActiveFolderCloudIcon';
import ActiveUserIcon from '../Icons/ActiveUserIcon';
import ActivePresentationChart from '../Icons/ActivePresentationChart';
import ActiveHomeIcon from '../Icons/ActiveHomeIcon';
import ActiveGraphIcon from '../Icons/ActiveGraphIcon';
import ActiveChartSquareIcon from '../Icons/ActiveChartSquareIcon';
import MarketPlaceIcon from '../Icons/MarketPlaceIcon';
// import DocGenIcon from '../Icons/DocGenIcon';
import Banner from './components/Banner';

const { Text } = Typography;
interface ExtendedMenuItemType extends MenuItemType {
  href?: string;
  children?: ExtendedMenuItemType[];
}

interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}

const Sidebar = () => {
  const { t, i18n } = useTranslation('layout', {
    keyPrefix: 'sidebar',
  });

  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const [isCompanyReviewEligiable, setIsCompanyReviewEligiable] = useState<ExtendedMenuItemType | null>({
    icon: '',
    key: '',
    label: '',
    href: '',
  });

  const { selectedCompany, setIsPremiumPlan, isPremiumPlan, setExpiresAt } = useSelectedCompanyStore((state) => state);
  const { setPermissions } = usePermissionsStore((state) => state);

  const { data: currCompany, isLoading: isCompanyLoading } = useGetCompany({
    companyID: selectedCompany?.cid as number,
  });

  const navigate = useNavigate();

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(screens.xl || false);

  const { pathname } = useLocation();

  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  useLayoutEffect(() => {
    setIsSidebarCollapsed(!screens.xl);
  }, [screens.xl]);

  const topLevelMenuItems: ExtendedMenuItemType[] = [
    {
      icon: defaultSelectedKeys.includes('dashboard') ? <ActiveHomeIcon /> : <DashboardIcon />,
      label: t('dashboard'),
      key: 'dashboard',
      href: '/company/dashboard',
    },
    {
      icon: defaultSelectedKeys.includes('investmentOverview') ? <ActiveEmptyWalletIcon /> : <EmptyWalletIcon />,
      label: t('investmentOverview.title'),
      key: 'investmentOverview',
      href: '/company/investment-overview',
      children: [
        {
          key: 'myInvestments',
          label: t('investmentOverview.myInvestments'),
          href: '/company/investment-overview/my-investments',
        },
        {
          key: 'transactionRequests',
          label: t('investmentOverview.transactionRequests'),
          href: '/company/investment-overview/transaction-requests',
        },
        {
          key: 'Contracts',
          label: t('investmentOverview.contracts'),
          href: '/company/investment-overview/contracts',
        },
        {
          key: 'myActivity',
          label: t('investmentOverview.myActivity'),
          href: '/company/investment-overview/my-activity',
        },
      ],
    },
    {
      icon: defaultSelectedKeys.includes('captable') ? <ActiveGraphIcon /> : <GraphIcon />,
      label: t('captable.title'),
      key: 'captable',
      href: '/captable',
      children: [
        {
          key: 'captableOverview',
          label: t('captable.captableOverview'),
          href: '/captable/overview',
        },
        {
          key: 'equities',
          label: t('captable.equities'),
          href: '/captable/equities',
        },
        {
          key: 'options',
          label: t('captable.options'),
          href: '/captable/options',
        },
        {
          key: 'convertibleInstruments',
          label: t('captable.convertibleInstruments'),
          href: '/captable/convertible-instruments',
        },
        {
          key: 'vestingSchedule',
          label: t('captable.vestingSchedule'),
          href: '/captable/vesting-schedule',
        },
        {
          key: 'transactionsHistory',
          label: t('captable.transactionsHistory'),
          href: '/captable/history',
        },
      ],
    },
    {
      icon: defaultSelectedKeys.includes('valuation') ? <ActivePresentationChart /> : <PresentationChartIcon />,
      label: t('valuation.title'),
      key: 'valuation',
      href: '/valuation',
      children: [
        {
          key: 'valuationDashboard',
          label: t('valuation.dashboard'),
          href: '/valuation/dashboard',
        },
        {
          key: 'questionnaire',
          label: t('valuation.questionnaire'),
          href: '/valuation/questionnaire',
        },
        {
          key: 'financial',
          label: t('valuation.financial'),
          href: '/valuation/financial',
        },
        {
          key: 'reports',
          label: t('valuation.reports'),
          href: '/valuation/reports',
        },
      ],
    },
    {
      icon: defaultSelectedKeys.includes('scenarioModeling') ? <ActiveChartSquareIcon /> : <ChartSquareIcon />,
      label: t('scenarioModeling.parentTitle'),
      key: 'scenarioModeling',
      href: '/scenario-modeling',
      children: [
        {
          key: 'investmentRound',
          href: '/scenario-modeling/investment-round',
          label: <Link to="/scenario-modeling/investment-round">{t('scenarioModeling.investmentRound')}</Link>,
        },
        {
          key: 'exitOrliquidModeling',
          href: '/scenario-modeling/exit-modeling',
          label: <Link to="/scenario-modeling/exit-modeling">{t('scenarioModeling.exitOrliquidModeling')}</Link>,
        },
      ],
    },
    {
      icon: defaultSelectedKeys.includes('dataRoom') ? <ActiveFolderCloudIcon /> : <FolderCloudIcon />,
      label: t('dataRoom'),
      key: 'dataRoom',
      href: '/data-rooms/main',
    },
    {
      icon: defaultSelectedKeys.includes('usersManagement') ? <ActiveUserIcon /> : <UserIcon />,
      label: t('usersManagement.title'),
      key: 'usersManagement',
      href: '/users-management',
      children: [
        {
          key: 'usersList',
          label: t('usersManagement.usersList'),
          href: '/users-management/users',
        },
        {
          key: 'accessLevels',
          label: t('usersManagement.rolesAndPermissions'),
          href: '/users-management/access-levels',
        },
        {
          key: 'activityLogs',
          label: t('usersManagement.activityLogs'),
          href: '/users-management/activity-logs',
        },
      ],
    },
    {
      icon: <MarketPlaceIcon active={defaultSelectedKeys.includes('marketPlace')} />,
      label: t('marketPlace'),
      key: 'marketPlace',
      href: '/market-place',
    },
    {
      icon: <AddOnsIcon active={defaultSelectedKeys.includes('addOns')} />,
      label: t('addOns'),
      key: 'addOns',
      href: '/add-ons',
      children: [
        {
          key: 'consultation',
          label: t('consultationServices'),
          href: 'addon/consultation',
        },
      ],
    },
    // {
    //   icon: <DocGenIcon active={defaultSelectedKeys.includes('docGen')} />,
    //   label: t('docGen'),
    //   key: 'docGen',
    //   href: '/document-generator/templates',
    // },
    isCompanyReviewEligiable!,
  ];

  useEffect(() => {
    if (currCompany) {
      //* triggers ellipsis based on length as element dimensions are unreliable due to rendering inconsistencies
      const maxCharCount = 14;
      setIsTextOverflowing(currCompany?.company_data?.name?.length > maxCharCount);
      setExpiresAt(currCompany.expired_at);
      setIsPremiumPlan(currCompany?.current_plan?.price > 0);
      setPermissions(currCompany.user_permissions);
    }

    if (currCompany?.is_review_allowed) {
      setIsCompanyReviewEligiable({
        icon: defaultSelectedKeys.includes('companyReviews') ? <ActiveMiskIcon /> : <MiskIcon />,
        label: t('companyReviews'),
        key: 'companyReviews',
        href: '/company-review/status',
      });
    } else setIsCompanyReviewEligiable(null);
  }, [currCompany, i18n.language]);

  // * This return active route and child route if any with his parent
  const activeRoute = topLevelMenuItems.reduce<{ key: Key; childKey?: Key }>(
    (acc, item) => {
      if (item?.href === pathname) {
        // Return parent item key if match found
        return { key: item.key };
      }

      const childMatch = item?.children?.find((child) => pathname.includes(child.href as string));
      if (childMatch) {
        // Return both parent and child keys if child match found
        return { key: item.key, childKey: childMatch.key };
      }

      return acc; // Continue with accumulated value
    },
    { key: '', childKey: '' },
  );
  const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {};
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2.forEach((item) => {
        if (item?.key) {
          key[item.key] = level;
        }
        if (item?.children) {
          return func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const levelKeys = getLevelKeys(topLevelMenuItems as LevelKeysProps[]);

  const onOpenChange = (updatedOpenedKeys: string[]) => {
    const currentOpenKey = updatedOpenedKeys.find((key) => openKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = updatedOpenedKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setOpenKeys(
        updatedOpenedKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      // close
      setOpenKeys([]);
    }
  };

  const isAddNewCompanyCurrentPath = useMemo(() => pathname === '/company/new', [pathname]);

  useEffect(() => {
    if (pathname !== '/go-premium/no-access') {
      setDefaultSelectedKeys(() => [(activeRoute.childKey || activeRoute.key) as string]);
      setOpenKeys(() => [activeRoute.key as string]);
    }
  }, [selectedCompany, pathname]);

  return (
    <StyledSider
      width={screens.xl ? 'fit-content' : '100%'}
      className={isSidebarCollapsed ? 'is-sidebar-collapsed' : ''}
    >
      {!screens.xl && (
        <ResponsiveSidebar
          isSidebarCollapsed={isSidebarCollapsed}
          setIsSidebarCollapsed={setIsSidebarCollapsed}
          isCompanyLoading={isCompanyLoading}
        />
      )}
      <div
        style={{
          height: isSidebarCollapsed ? 'unset' : 'calc(100% - 48px)',
          transition: 'margin-inline-start 0.3s ease-in-out',
          marginInlineStart: isSidebarCollapsed ? '-100%' : '0',
        }}
      >
        <Flex className="sidebar-main-child">
          <CompaniesSidebar isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed} />
          {!isAddNewCompanyCurrentPath && (
            <Flex
              vertical
              style={{
                width: screens.xl ? '230px' : '100%',
              }}
            >
              {(isCompanyLoading || currCompany) && (
                <Flex
                  gap={32}
                  align="center"
                  style={{
                    background: 'white',
                    borderBottom: `1px solid ${themeToken.menuSubmenuBorderColor}`,
                    borderRight: `1px solid ${themeToken.menuSubmenuBorderColor}`,
                    minHeight: '54px',
                    position: 'relative',
                  }}
                >
                  {screens.xl && (
                    <>
                      {isPremiumPlan && !isCompanyLoading && <PremiumBadge />}
                      {isCompanyLoading ? (
                        <CellSkeleton />
                      ) : (
                        <Text
                          style={{
                            fontWeight: `${themeToken.fontWeightStrong}`,
                            color: `${themeToken['branding-primary-6']}`,
                            width: '100%',
                            maxWidth: '125px',
                            paddingInlineStart: `${themeToken.padding}px`,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          ellipsis={
                            isTextOverflowing && {
                              tooltip: currCompany?.company_data.name,
                            }
                          }
                        >
                          {currCompany?.company_data.name}
                        </Text>
                      )}
                      {isCompanyLoading ? (
                        <CellSkeleton width="36px" block={false} />
                      ) : (
                        <Tooltip title={t('editCompany')}>
                          <StyledButton
                            type="text"
                            onClick={() => navigate(`/company/${selectedCompany?.cid}/edit`)}
                            disabled={!selectedCompany?.is_owner}
                          >
                            <EditCompanyIcon />
                          </StyledButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                </Flex>
              )}

              <StyledMenu
                mode="inline"
                items={topLevelMenuItems}
                onClick={({ key }) => {
                  const itemHref =
                    topLevelMenuItems.find((item) => item?.key === key)?.href ||
                    topLevelMenuItems.flatMap((item) => item?.children || []).find((child) => child.key === key)?.href;
                  if (!screens.xl) {
                    setIsSidebarCollapsed(true);
                  }
                  navigate(itemHref as string);
                }}
                selectedKeys={defaultSelectedKeys}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                inlineIndent={16}
                expandIcon={({ isOpen }) => (isOpen ? <MinusIcon /> : <PlusIcon />)}
              />
              <Banner />
            </Flex>
          )}
        </Flex>
      </div>
    </StyledSider>
  );
};

export default Sidebar;
