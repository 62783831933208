import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { App, Flex, Grid, Typography } from 'antd';

import DashboardIcon from '@/components/Icons/DashboardIcon';
import { PageWrapper } from '@/components/Shared/Wrapper';
import CommonTable from '@/components/Shared/CommonTable';
import { useGetConvertibleInstruments } from '@/features/captable/api/ConvertibleInstruments/getConvertibleInstruments';
import { StyledButton } from '@/lib/theme/components/Button';
import PlusIcon from '@/components/Icons/PlusIcon';
import themeToken from '@lib/theme/tokens/index';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { StyledProgress } from '@/lib/theme/components/Progress';
import { useFetchCaptable } from '@/features/captable/api/CaptableOverview/getCaptable';
import { CaptableOverViewColumns } from '@/features/captable/utils/captableOverviewTablesColumns';
import { RightArrowIcon } from '@/components/Icons/RightArrowIcon';
import { CellSkeleton } from '@/components/Shared/CellSkeleton';
import { usePermissionsStore } from '@/stores/Permissions';
import { sumArrayItems } from '@/features/captable/utils/sumArrayItems';
import { formatNumber } from '@/utils/formatNumber';
import PaymentStatusModal from '@/features/premium/components/PaymentStatusModal';
import PlanUpgradeModal from '@/features/premium/components/PlanUpgradeModal';
import { LimitsCheckerContext } from '@/components/LimitsChecker';
import { useTrackEvent } from '@/hooks/useTrackEvents';
import ForSaleModal from '@/features/companyListing/components/ForsaleModal';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import MainContainer from '@/components/Shared/MainContainer';

import DashboardCard from '../components/DashboardCard';
import { convertibleInstrumentsColumns } from '../utils/convertibleInstrumentsColumns';
import ValuationHistory from '../components/ValuationHistory';
import { useGetPersonalHoldings } from '../api/getPersonalHoldings';
import { useGetInvitedUsers } from '../api/getInvitedUsers';
import { useGetCompany } from '../api/getCompany';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const Dashboard = () => {
  const { sendMixpanelEvent } = useTrackEvent({ page: 'company-dashboard' });
  const { notification: toaster } = App.useApp();
  const { disableRecordsAddition } = useOutletContext<LimitsCheckerContext>() || {};
  // Modals flags
  const [queryParams, setQueryParams] = useSearchParams();
  const [paymentStatus] = useState<string>(queryParams.get('payment_status') as '0' | '1');
  const [paymentMessage] = useState<string>(queryParams.get('message') as string);
  const [planName] = useState<string>(queryParams.get('plan_name') as string);
  const [nextBilling] = useState<string>(queryParams.get('next_billing') as string);
  const [showUpsellingModal] = useState<string>(queryParams.get('upselling_plan') as '0' | '1');
  const forSaleModalCounter = localStorage.getItem('forSaleModalCounter');
  // Modals flags

  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [upsellingModal, setUpsellingModal] = useState(false);
  const [forSaleModalOpen, setForSaleModalOpen] = useState(false);
  const { permissions } = usePermissionsStore((state) => state);

  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });

  const { t: translate } = useTranslation('pageTitles');

  useDocumentTitle(translate('dashboard'));

  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const { data: companyData } = useGetCompany({
    companyID: selectedCompany?.cid as number,
  });
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { data: captableData, isLoading: isCaptableLoading } = useFetchCaptable({
    company_id: selectedCompany?.cid as number,
  });
  const { data: personalHoldingsData } = useGetPersonalHoldings({
    companyID: selectedCompany?.cid as number,
  });
  const { data: convertibleInstruments, isLoading: isConvertibleInstrumentsLoading } = useGetConvertibleInstruments({
    urlParams: {
      companyID: selectedCompany?.cid as number,
    },
    queryParams: {},
  });
  const { data: invitedUsers, isLoading: isInvitedUsersLoading } = useGetInvitedUsers({
    companyID: selectedCompany?.cid as number,
  });

  const overviewColumns = CaptableOverViewColumns(isCaptableLoading, t);
  const convertibleTableColumns = convertibleInstrumentsColumns(
    isConvertibleInstrumentsLoading,
    companyData?.company_data.company_currency.code as string,
    t,
  );

  const progress =
    ((captableData?.total_issued_shares as number) / (captableData?.total_authorized_shares as number)) * 100;

  const overviewTableOwnershipSum = sumArrayItems(
    captableData?.modifiedEquities.map(({ ownership }) => Number(ownership.replace('%', ''))) ?? [0],
  );

  const handleClosePlanUpgradeModal = () => {
    queryParams.delete('upselling_plan');
    setQueryParams(queryParams);
    setUpsellingModal(false);
  };

  const handleCloseForSaleModal = () => {
    queryParams.delete('forsale_modal');
    setQueryParams(queryParams);
    setForSaleModalOpen(false);
  };

  useEffect(() => {
    if (paymentStatus && paymentStatus === '1') {
      setPaymentStatusModal(true);
    } else if (paymentStatus && paymentStatus === '0') {
      toaster.error({
        message: 'Something Went Wrong',
        description: paymentMessage,
      });
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (showUpsellingModal && showUpsellingModal === '1') {
      setUpsellingModal(true);
    }
  }, [showUpsellingModal]);

  useEffect(() => {
    if (queryParams.get('forsale_modal') === '1' && Number(forSaleModalCounter || 0) < 3) {
      setForSaleModalOpen(true);
      localStorage.setItem('forSaleModalCounter', String(Number(forSaleModalCounter || 0) + 1));
    }
  }, [queryParams]);

  return (
    <MainContainer
      title={t('dashboard')}
      breadcrumbItems={[
        {
          title: (
            <>
              <DashboardIcon />
              {t('dashboard')}
            </>
          ),
        },
      ]}
    >
      <Flex gap={16} vertical={!screens.xl}>
        <Flex gap={16} vertical>
          <Flex gap={16} vertical={!screens.lg}>
            <DashboardCard dark="true">
              <Flex justify="space-between" gap={8} vertical>
                <Flex vertical>
                  <Title level={5}>{t('personalHoldings')}</Title>
                </Flex>
                <Text
                  style={{
                    fontSize: 30,
                    color: '#fff',
                  }}
                  strong
                >
                  {formatNumber(personalHoldingsData?.user_ownership ?? 0, { maximumFractionDigits: 2 })} %
                </Text>
                <Flex gap={2} vertical className="details">
                  <Text>{`${formatNumber(personalHoldingsData?.common_shares as number)} ${t('commonShares')}`}</Text>
                  <Text>
                    {`${formatNumber(personalHoldingsData?.preferred_shares as number)} ${t('preferredShares')}`}
                  </Text>
                  <Text>{`${formatNumber(personalHoldingsData?.options as number)} ${t('options')}`}</Text>
                  <Text>
                    {`${formatNumber(personalHoldingsData?.convertible_instruments as number)} ${t(
                      'convertibleInstrument',
                    )}`}
                  </Text>
                </Flex>
              </Flex>
            </DashboardCard>
            <DashboardCard>
              <Flex gap={18} justify="space-between" vertical>
                <Title level={5}>{t('authorizedShares')}</Title>
                <Text
                  style={{
                    fontSize: 30,
                    color: '#2a186a',
                    textAlign: 'center',
                  }}
                  strong
                >
                  {isCaptableLoading ? (
                    <CellSkeleton />
                  ) : (
                    `${formatNumber(captableData?.total_authorized_shares as number)} ${t('shares')}`
                  )}
                </Text>
                <Flex gap={8} vertical>
                  <Flex justify="space-between" style={{ fontSize: themeToken.fontSize }}>
                    <Text style={{ display: 'flex', flexDirection: 'column', color: themeToken['branding-primary-6'] }}>
                      <span style={{ fontWeight: 600 }}>
                        {isCaptableLoading ? (
                          <CellSkeleton />
                        ) : (
                          formatNumber(captableData?.total_issued_shares as number)
                        )}
                      </span>
                      {t('sharesIssued')}
                    </Text>

                    <Text
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        color: themeToken['branding-natural-7'],
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>
                        {isCaptableLoading ? (
                          <CellSkeleton />
                        ) : (
                          formatNumber(captableData?.total_available_shares as number)
                        )}
                      </span>
                      {t('availableShares')}
                    </Text>
                  </Flex>
                  <StyledProgress
                    percent={progress}
                    showInfo={false}
                    style={{
                      margin: 0,
                    }}
                  />
                </Flex>
              </Flex>
            </DashboardCard>
            <DashboardCard>
              <Flex gap={26} justify="space-between" vertical>
                <Title level={5}>{t('invitedStakeholders')}</Title>
                <Flex gap={6} vertical>
                  <Flex align="center" vertical>
                    <Text
                      strong
                      style={{
                        fontSize: 30,
                        color: '#2a186a',
                      }}
                    >
                      {isInvitedUsersLoading ? <CellSkeleton /> : formatNumber(invitedUsers?.shared_access_count ?? 0)}
                    </Text>
                    <Text
                      style={{
                        color: '#2a186a',
                      }}
                    >
                      {t('invitationsAccepted')}
                    </Text>
                  </Flex>
                </Flex>
                <Flex align="center" vertical className="shareholder_box">
                  <Text strong>
                    {isInvitedUsersLoading ? <CellSkeleton /> : formatNumber(invitedUsers?.invited_count ?? 0)}{' '}
                    {t('shareholdersInvited')}
                  </Text>
                </Flex>
              </Flex>
            </DashboardCard>
          </Flex>
          <Flex gap={16} vertical>
            <PageWrapper
              vertical
              style={{
                minHeight: 'unset',
              }}
            >
              <Flex
                vertical={screens.xs}
                gap={8}
                justify="space-between"
                align={screens.xs ? 'flex-start' : 'center'}
                style={{ marginBottom: `${themeToken.margin}px` }}
              >
                <Flex vertical>
                  <Text
                    style={{
                      color: themeToken['branding-primary-6'],
                      fontWeight: 600,
                      fontSize: themeToken.fontSizeLG,
                      marginBottom: themeToken.marginXXS,
                    }}
                  >
                    {t('captableOverview')}
                  </Text>
                </Flex>
                {!disableRecordsAddition && (
                  <StyledButton
                    style={{ padding: `${themeToken.paddingXXS}px ${themeToken.paddingLG}px` }}
                    size="small"
                    ghost
                    type="primary"
                    icon={<PlusIcon />}
                    onClick={() => {
                      sendMixpanelEvent('add-equities-btn-clicked');
                      navigate('/captable/equities/new');
                    }}
                    disabled={!permissions.includes('company.cap-table.equity.add')}
                  >
                    {t('addNewEquity')}
                  </StyledButton>
                )}
              </Flex>
              <CommonTable
                isLoading={isCaptableLoading}
                pagination={false}
                columns={overviewColumns}
                dataSource={captableData?.modifiedEquities}
                summaryTitle={t('total')}
                summaryValues={[
                  `${captableData?.total_issued_shares ? formatNumber(captableData.total_issued_shares) : '-'}`,
                  `${(overviewTableOwnershipSum > 100 ? 100 : overviewTableOwnershipSum).toFixed(2)}%`,
                ]}
              />
            </PageWrapper>
            <PageWrapper
              vertical
              style={{
                minHeight: 'unset',
              }}
            >
              <Flex
                justify="space-between"
                style={{
                  marginBottom: themeToken.marginMD,
                }}
                vertical={!screens.sm}
              >
                <Title
                  level={5}
                  style={{
                    color: themeToken['branding-primary-6'],
                    fontWeight: 600,
                    marginBottom: themeToken.marginXXS,
                  }}
                >
                  {t('convertibleInstruments')}
                </Title>
                <Flex gap={12}>
                  {!disableRecordsAddition && (
                    <StyledButton
                      size="small"
                      ghost
                      type="primary"
                      icon={<PlusIcon />}
                      onClick={() => {
                        sendMixpanelEvent('add-instrument-btn-clicked');
                        navigate('/captable/convertible-instruments/new');
                      }}
                      disabled={!permissions.includes('company.cap-table.convertible-instrument.add')}
                    >
                      {t('addNewInstruments')}
                    </StyledButton>
                  )}

                  <StyledButton
                    size="small"
                    ghost
                    type="primary"
                    onClick={() => {
                      sendMixpanelEvent('view-more-instruments-btn-clicked');
                      navigate('/captable/convertible-instruments');
                    }}
                    disabled={!permissions.includes('company.cap-table.convertible-instrument.list')}
                  >
                    {t('viewMore')}
                  </StyledButton>
                </Flex>
              </Flex>
              <CommonTable
                columns={convertibleTableColumns}
                dataSource={convertibleInstruments?.data}
                isLoading={isConvertibleInstrumentsLoading}
                pagination={false}
              />
            </PageWrapper>
            <PageWrapper
              vertical
              style={{
                minHeight: 'unset',
              }}
            >
              <ValuationHistory />
            </PageWrapper>
          </Flex>
        </Flex>
        <DashboardCard
          style={{
            height: 'fit-content',
          }}
        >
          <Flex gap={10} vertical className="quick_actions">
            <Title level={5}>{t('quickActions')}</Title>
            <StyledButton
              type="text"
              onClick={() => {
                sendMixpanelEvent('quick-actions-add-equity-btn-clicked');
                navigate('/captable/equities/new');
              }}
              disabled={!permissions.includes('company.cap-table.equity.add') || disableRecordsAddition}
            >
              {t('addEquity')} <RightArrowIcon />
            </StyledButton>
            <StyledButton
              type="text"
              onClick={() => {
                sendMixpanelEvent('quick-actions-add-option-btn-clicked');
                navigate('/captable/options/new');
              }}
              disabled={!permissions.includes('company.cap-table.option.add') || disableRecordsAddition}
            >
              {t('addOption')} <RightArrowIcon />
            </StyledButton>
            <StyledButton
              type="text"
              onClick={() => {
                sendMixpanelEvent('quick-actions-add-convertible-btn-clicked');
                navigate('/captable/convertible-instruments/new');
              }}
              disabled={!permissions.includes('company.cap-table.convertible-instrument.add') || disableRecordsAddition}
            >
              {t('addConvertibleInstruments')} <RightArrowIcon />
            </StyledButton>
            <StyledButton
              type="text"
              onClick={() => {
                sendMixpanelEvent('quick-actions-investment-round-btn-clicked');
                navigate('/scenario-modeling/investment-round');
              }}
              disabled={!permissions.includes('company.modeling.round')}
            >
              {t('simulateInvestmentRound')} <RightArrowIcon />
            </StyledButton>
            <StyledButton
              type="text"
              onClick={() => {
                sendMixpanelEvent('quick-actions-exit-modeling-btn-clicked');
                navigate('/scenario-modeling/exit-modeling');
              }}
              disabled={!permissions.includes('company.modeling.exit') || disableRecordsAddition}
            >
              {t('simulateExitScenario')} <RightArrowIcon />
            </StyledButton>
          </Flex>
        </DashboardCard>
      </Flex>
      <PaymentStatusModal
        planName={planName}
        date={nextBilling}
        isOpen={paymentStatusModal}
        setIsOpen={setPaymentStatusModal}
      />
      <PlanUpgradeModal isOpen={upsellingModal} onCancel={handleClosePlanUpgradeModal} />
      <ForSaleModal isOpen={forSaleModalOpen} onCancel={handleCloseForSaleModal} />
    </MainContainer>
  );
};

export default Dashboard;
